import { useRef, useState, useEffect } from "react";
import { Icon, IconButton } from "@fluentui/react";
import "./Thread.pure.css";

interface Props {
    threadId: string;
    title: string;
    selectedThreadId: string;
    onThreadClicked: (threadId: string, threadTitle: string) => void;
    onRemoveThreadClicked: (threadId: string) => void;
    onThreadTitleChanged: (threadId: string, title: string) => void;
}

export const Thread = ({ threadId, title, selectedThreadId, onThreadClicked, onRemoveThreadClicked, onThreadTitleChanged }: Props) => {
    const [inputTitle, setInputTitle] = useState<string>(title);
    const [isOpen, setIsOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isDelete, setIsDelete] = useState(false);

    const threadTitle = useRef<string>(title);
    const insideRef = useRef<HTMLDivElement>(null);

    const handleSelectThread = (threadId: string) => {
        if (threadId === selectedThreadId) return false;
        onThreadClicked(threadId, title);
    };

    const handleEdit = (bool: boolean, event: any) => {
        event.stopPropagation();
        setIsEdit(bool);
    };

    const handleRemoveClick = (bool: boolean, event: any) => {
        event.stopPropagation();
        setIsDelete(bool);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputTitle(e.target.value);
    };

    const handleDismiss = (threadId: string, event: any) => {
        event.stopPropagation();
        setIsEdit(false);
        setIsOpen(false);
        setIsDelete(false);
        setInputTitle(threadTitle.current);
    };

    const handleSave = (threadId: string, event: any) => {
        event.stopPropagation();
        onThreadTitleChanged(threadId, inputTitle);
        setInputTitle(inputTitle);
        setIsEdit(false);
        setIsOpen(false);
    };

    const handleRemoveSubmit = (threadId: string, event: any) => {
        event.stopPropagation();
        onRemoveThreadClicked(threadId);
        setIsDelete(false);
        setIsOpen(false);
    };

    useEffect(() => {
        threadTitle.current = inputTitle;
    }, [isEdit]);

    useEffect(() => {
        const el = insideRef.current; //対象の要素を取得
        if (!el) return; //対象の要素がなければ何もしない

        const hundleClickOutside = (e: MouseEvent) => {
            if (!el?.contains(e.target as Node)) {
                // 対象のDIV以外をクリックしたとき
                setIsEdit(false);
                setIsOpen(false);
                setIsDelete(false);
                setInputTitle(threadTitle.current);
            } else {
                // 対象のDIVをクリックしたとき
                setIsOpen(true);
            }
        };
        document.addEventListener("click", hundleClickOutside);

        return () => {
            document.removeEventListener("click", hundleClickOutside);
        };
    }, [insideRef]);

    const iconStyle = { color: "white" };
    const iconPropsEdit = { iconName: "Edit" };
    const iconPropsDelete = { iconName: "Delete" };

    return (
        <li className={threadId === selectedThreadId ? "active" : ""} data-threadid={threadId} onClick={() => handleSelectThread(threadId)}>
            <div ref={insideRef} className={isOpen ? "active" : ""}>
                <>{isDelete ? <Icon iconName="Delete" className="icon" /> : <Icon iconName="Chat" className="icon" />}</>
                <>
                    {isEdit ? (
                        <input type="text" className="editForm" value={inputTitle} onChange={handleChange} autoFocus />
                    ) : (
                        <p className="viewForm">{threadTitle.current}</p>
                    )}
                </>
                <>
                    <div className={`buttons ${isEdit || isDelete ? "hide" : ""}`}>
                        <IconButton
                            style={iconStyle}
                            iconProps={iconPropsEdit}
                            title="Edit thread title"
                            ariaLabel="Edit thread title"
                            onClick={e => handleEdit(true, e)}
                            disabled={false}
                        />
                        {!isEdit ? (
                            <IconButton
                                style={iconStyle}
                                iconProps={iconPropsDelete}
                                title="Remove this thread"
                                ariaLabel="Remove this thread"
                                onClick={e => handleRemoveClick(true, e)}
                                disabled={false}
                            />
                        ) : null}
                    </div>
                </>
                <>
                    {/* 編集時の表示 */}
                    {isEdit ? (
                        <IconButton
                            style={{ color: "white" }}
                            iconProps={{ iconName: "Checkmark" }}
                            title="Update thread title"
                            ariaLabel="Update thread title"
                            onClick={e => handleSave(threadId, e)}
                            disabled={false}
                        />
                    ) : null}
                    {isEdit ? (
                        <IconButton
                            style={{ color: "white" }}
                            iconProps={{ iconName: "Cancel" }}
                            title="Close edit form"
                            ariaLabel="Close edit form"
                            onClick={e => handleDismiss(threadId, e)}
                            disabled={false}
                        />
                    ) : null}
                </>
                <>
                    {/* 削除時の表示 */}
                    {isDelete ? (
                        <IconButton
                            style={{ color: "white" }}
                            iconProps={{ iconName: "Checkmark" }}
                            title="Purge this thread"
                            ariaLabel="Purge this thread"
                            onClick={e => handleRemoveSubmit(threadId, e)}
                            disabled={false}
                        />
                    ) : null}
                    {isDelete ? (
                        <IconButton
                            style={{ color: "white" }}
                            iconProps={{ iconName: "Cancel" }}
                            title="Close delete form"
                            ariaLabel="Close delete form"
                            onClick={e => handleDismiss(threadId, e)}
                            disabled={false}
                        />
                    ) : null}
                </>
            </div>
        </li>
    );
};

import { FormEvent } from "react";
import {
    Button,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    FluentProvider,
    webLightTheme
} from "@fluentui/react-components";
import styles from "./DialogSetting.module.css";

interface Props {
    setIsOpen: (isOpen: boolean) => void;
}

export const DialogSetting = ({ setIsOpen }: Props) => {
    const handleSubmit = async (ev: FormEvent) => {
        ev.preventDefault();
        setIsOpen(false);
    };

    return (
        <div className={styles.dialogWrapper}>
            <FluentProvider className={styles.provider} theme={webLightTheme}>
                <DialogSurface className={styles.dialogSurface}>
                    <form onSubmit={handleSubmit}>
                        <DialogBody>
                            <DialogTitle>Settings</DialogTitle>
                            <DialogContent>Dialog Contetent here.</DialogContent>

                            <DialogActions>
                                <DialogTrigger disableButtonEnhancement>
                                    <Button appearance="secondary">Close</Button>
                                </DialogTrigger>
                                <Button type="submit" appearance="primary">
                                    Do Something
                                </Button>
                            </DialogActions>
                        </DialogBody>
                    </form>
                </DialogSurface>
            </FluentProvider>
        </div>
    );
};

import { useState } from "react";
import { Dropdown, FluentProvider, Option, webLightTheme } from "@fluentui/react-components";
import type { DropdownProps } from "@fluentui/react-components";

import styles from "./DataTypeDropdown.module.css";

interface Props {
    pluginIdRef: React.MutableRefObject<string>;
}

export const DataTypeDropdown = ({ pluginIdRef }: Props, props: Partial<DropdownProps>) => {
    const [selectedOptions, setSelectedOptions] = useState<string[]>([""]);
    const [value, setValue] = useState("");

    const onOptionSelect: (typeof props)["onOptionSelect"] = (ev, data) => {
        setSelectedOptions(data.selectedOptions);
        setValue(data.optionText ?? "");
        console.log(data);
        pluginIdRef.current = data.selectedOptions[0];
    };

    return (
        <div className={styles.dataTypeDropdownContainer}>
            <FluentProvider className={styles.provider} theme={webLightTheme}>
                <Dropdown placeholder="データを選択してください" size="large" value={value} selectedOptions={selectedOptions} onOptionSelect={onOptionSelect}>
                    <Option value="1">AZPower 就業規則</Option>
                    <Option value="2">AZPower 業務マニュアル</Option>
                    <Option value="3">PowerSKILL 操作マニュアル</Option>
                </Dropdown>
            </FluentProvider>
        </div>
    );
};

import React from "react";
import ReactDOM from "react-dom/client";
import { initializeIcons } from "@fluentui/react";
import { FluentProvider, webDarkTheme } from "@fluentui/react-components";
import { PublicClientApplication } from "@azure/msal-browser";
import { getEnvironmentApi } from "./api";
import { getMsalConfig } from "./auth/authUtil";
import App from "./App";

import "./index.css";
import "github-markdown-css/github-markdown-dark.css";
import "highlight.js/styles/github-dark.css";

const environment = await getEnvironmentApi();
const msalConfig = getMsalConfig(environment);
const msalInstance = new PublicClientApplication(msalConfig);

await msalInstance
    .handleRedirectPromise()
    .then(tokenResponse => {
        if (!tokenResponse) {
            const allAccounts = msalInstance.getAllAccounts();
            if (allAccounts.length === 0) {
                msalInstance.loginRedirect();
            }
        } else {
            // Do something with the tokenResponse
        }
    })
    .catch(err => {
        console.log(err);
    });

if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

initializeIcons();

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        {/*<FluentProvider theme={webDarkTheme} className="framework-fluent-ui">*/}
        <App instance={msalInstance} environment={environment} />
        {/*</FluentProvider>*/}
    </React.StrictMode>
);

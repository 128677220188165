/**
 *
 * ラベル名はCSSで指定しています。
 *
 */
import { useState } from "react";
import { Radio, RadioGroup } from "@fluentui/react-components";
import * as CONST from "../../utils/constant";

import styles from "./ToggleModelButton.module.css";

interface Props {
    environment: any;
    aiModelRef: React.MutableRefObject<string>;
}

export const ToggleModelButton = ({ environment, aiModelRef }: Props) => {
    const disabledGPT4 = environment.modelGpt4Disabled || false;

    const [value, setValue] = useState(CONST.AI_MODEL_VALUE.GPT_3_5);

    const handleChange = (e: any, data: any) => {
        setValue(data.value);
        aiModelRef.current = data.value || CONST.AI_MODEL_VALUE.GPT_3_5;
    };

    return (
        <div className={styles.modelChange}>
            <RadioGroup className={styles.modelGroup} value={value} layout="horizontal" onChange={handleChange} aria-labelledby="">
                {!disabledGPT4 ? (
                    <>
                        <Radio className={styles.model35} value={CONST.AI_MODEL_VALUE.GPT_3_5} label="" />
                        <Radio className={styles.model40} value={CONST.AI_MODEL_VALUE.GPT_4} label="" />
                    </>
                ) : (
                    <Radio className={styles.model40} value={CONST.AI_MODEL_VALUE.GPT_4} label="" disabled />
                )}
            </RadioGroup>
            <div className={`${styles.circleSwitch} ${value === CONST.AI_MODEL_VALUE.GPT_3_5 ? styles.circleLeft : styles.circleRight}`}></div>
        </div>
    );
};

import { useEffect, useRef, useState } from "react";
import { Stack } from "@fluentui/react";
import { Send28Filled } from "@fluentui/react-icons";
import { confirmAlert } from "react-confirm-alert";

import styles from "./QuestionInput.module.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import "./QuestionInput.pure.css";
import { PromptLibrary } from "../PromptLibrary";
import { LoadingIndicator } from "../LoadingIndicator/LoadingIndicator";

interface Props {
    onSend: (question: string) => void;
    isLoading: boolean;
    placeholder?: string;
    clearOnSend?: boolean;
    environment: any;
}

export const QuestionInput = ({ onSend, placeholder, clearOnSend, isLoading, environment }: Props) => {
    const [question, setQuestion] = useState<string>("");
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const handleSend = (question: string) => {
        setIsOpenPromptLibrary(false);

        if (textAreaRef.current) {
            textAreaRef.current.style.height = "unset";
        }

        onSend(question);
    };

    const sendQuestion = () => {
        if (isLoading || !question.trim()) {
            return;
        }

        if (question.length < 4000) {
            handleSend(question);

            if (clearOnSend) {
                setQuestion("");
            }
        } else {
            confirmAlert({
                message: "入力したテキストは" + question.length + "文字です。\n長い文章は、複数回に分けて入力することを推奨します。\n\nこのまま送信しますか？",
                buttons: [
                    {
                        label: "送信する",
                        onClick: () => {
                            handleSend(question);

                            if (clearOnSend) {
                                setQuestion("");
                            }
                        }
                    },
                    {
                        label: "キャンセル",
                        onClick: () => {
                            return;
                        }
                    }
                ]
            });
        }
    };

    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
        if (ev.key === "Enter" && !ev.shiftKey) {
            ev.preventDefault();
            sendQuestion();
        }
    };

    const onQuestionChange = (event: any) => {
        setPrompt(event.target.value);
    };

    const handleClickInsertPrompt = (prompt: string) => {
        setPrompt(prompt);
    };

    const setPrompt = (prompt: string) => {
        if (!prompt) {
            setQuestion("");
        } else {
            setQuestion(prompt);
        }
    };

    useEffect(() => {
        if (!textAreaRef.current) return;

        if (question) {
            console.log(textAreaRef.current.scrollHeight);
            if (textAreaRef.current.scrollHeight <= 56) return;

            textAreaRef.current.style.height = "auto";
            textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px";
            textAreaRef.current.scrollTop = textAreaRef.current.scrollHeight;
        } else {
            textAreaRef.current.style.height = "";
        }
    }, [question]);

    const sendQuestionDisabled = isLoading || !question.trim();

    //プロンプトLibraryの開閉
    const [isOpenPromptLibrary, setIsOpenPromptLibrary] = useState(false);

    return (
        <>
            {environment.usePromptLibrary && (
                <PromptLibrary
                    onClickUseThisPrompt={handleClickInsertPrompt}
                    isOpenPromptLibrary={isOpenPromptLibrary}
                    setIsOpenPromptLibrary={setIsOpenPromptLibrary}
                />
            )}
            <div className={styles.questionInput}>
                <div className={styles.indicator}>{isLoading && <LoadingIndicator />}</div>
                <Stack horizontal className={styles.questionInputContainer}>
                    <div className={styles.questionInputTextArea}>
                        <textarea value={question} placeholder={placeholder} onChange={onQuestionChange} onKeyDown={onEnterPress} ref={textAreaRef}></textarea>
                    </div>
                    <div className={styles.questionInputButtonsContainer}>
                        <div
                            className={`${styles.questionInputSendButton} ${sendQuestionDisabled ? styles.questionInputSendButtonDisabled : ""}`}
                            onClick={sendQuestion}
                        >
                            <Send28Filled primaryFill="rgba(115, 118, 225, 1)" />
                        </div>
                    </div>
                </Stack>
            </div>
        </>
    );
};

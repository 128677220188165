import { useEffect, useState } from "react";
import { Accordion, AccordionHeader, AccordionItem, AccordionPanel, AccordionToggleEventHandler, Button, Tab, TabList } from "@fluentui/react-components";
import { ArrowExportUp20Regular, ChevronDoubleDown16Regular, ChevronDoubleUp16Regular, ChevronDown16Regular, ChevronUp16Regular } from "@fluentui/react-icons";
import type { SelectTabData, SelectTabEvent, TabValue } from "@fluentui/react-components";

import { PromptLibraryGetRequest, PromptLibraryGetResponse, promptLibraryGetApi } from "../../api";
import * as CONST from "../../utils/constant";

import styles from "./PromptLibrary.module.css";

interface Props {
    onClickUseThisPrompt: (prompt: string) => void;
    isOpenPromptLibrary: boolean;
    setIsOpenPromptLibrary: (isOpenPromptLibrary: boolean) => void;
}

export const PromptLibrary = ({ onClickUseThisPrompt, isOpenPromptLibrary, setIsOpenPromptLibrary }: Props) => {
    const handleClickPromptLibrary = () => {
        setIsOpenPromptLibrary(!isOpenPromptLibrary);
    };

    const [selectedTabValue, setSelectedTabValue] = useState<TabValue>("");

    const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
        setSelectedTabValue(data.value);
    };

    const [openItems, setOpenItems] = useState("0");

    const handleToggle: AccordionToggleEventHandler = (event, data) => {
        if (data.value === openItems) {
            setOpenItems("0");
        } else {
            setOpenItems(data.value as string);
        }
    };

    const callApiGetPromptLibraries = async (userId: string) => {
        try {
            const request: PromptLibraryGetRequest = {
                userId: userId
            };
            const result = await promptLibraryGetApi(request);
            const promptLibraries: PromptLibraryGetResponse = {
                status: result.status,
                error: result.error ?? "",
                promptLibraries: result.promptLibraries ?? []
            };
            return promptLibraries.promptLibraries;
        } catch (e) {
            alert(e);
        } finally {
            //NOP
        }
    };

    interface PromptLibrary {
        tabId: string;
        tabName: string;
        groups: {
            groupId: string;
            groupName: string;
            prompts: {
                promptId: string;
                prompt: string;
            }[];
        }[];
    }

    const [promptLibraries, setPromptLibraries] = useState<PromptLibrary[]>([]);

    useEffect(() => {
        const fetchPromptLibraries = async () => {
            const promptLibraries = (await callApiGetPromptLibraries("")) ?? [];
            setPromptLibraries(promptLibraries);
            if (promptLibraries.length > 0) setSelectedTabValue(promptLibraries[0].tabId);
        };
        fetchPromptLibraries();
    }, []);

    useEffect(() => {
        if (!isOpenPromptLibrary) {
            setSelectedTabValue("tab1");
            setOpenItems("0");
        }
    }, [isOpenPromptLibrary]);

    return (
        <>
            {promptLibraries.length > 0 && (
                <div className={styles.templateLibrary}>
                    <div className={styles.buttons}>
                        <button onClick={handleClickPromptLibrary} type="button" title="">
                            <span>{isOpenPromptLibrary ? <ChevronDoubleDown16Regular /> : <ChevronDoubleUp16Regular />}</span>
                            <span>プロンプトLibrary</span>
                        </button>
                    </div>
                    <div className={`${styles.contents} ${isOpenPromptLibrary ? styles.show : styles.hide}`}>
                        <div className={styles.header}>
                            <TabList selectedValue={selectedTabValue} onTabSelect={onTabSelect} className={styles.tabs} appearance="subtle">
                                {promptLibraries.map((library, index) => (
                                    <Tab
                                        key={index}
                                        id={library.tabId}
                                        value={library.tabId}
                                        className={`${styles.tab} ${selectedTabValue === library.tabId ? styles.selected : ""}`}
                                    >
                                        {library.tabName}
                                    </Tab>
                                ))}
                            </TabList>
                        </div>
                        <div className={styles.body}>
                            <div className={styles.tabContents}>
                                {promptLibraries.map((tab, tabIndex) => (
                                    <div key={tabIndex}>
                                        {selectedTabValue === tab.tabId && (
                                            <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
                                                {tab.groups.map((group, groupIndex) => (
                                                    <AccordionItem key={groupIndex} value={group.groupId} className={styles.tabContent}>
                                                        <AccordionHeader
                                                            className={`${styles.accordionHeader} ${
                                                                openItems === group.groupId ? styles.expanded : styles.collapsed
                                                            }`}
                                                            expandIconPosition="end"
                                                            expandIcon={openItems === group.groupId ? <ChevronUp16Regular /> : <ChevronDown16Regular />}
                                                        >
                                                            {group.groupName}
                                                        </AccordionHeader>
                                                        <AccordionPanel className={styles.accordionPanel}>
                                                            <div className={styles.accordionBody}>
                                                                {group.prompts.map((prompt, promptIndex) => (
                                                                    <div key={promptIndex} className={styles.box}>
                                                                        <div className={styles.content}>{prompt.prompt}</div>
                                                                        <div className={styles.buttonBlock}>
                                                                            <Button
                                                                                className={styles.buttonConfirm}
                                                                                icon={<ArrowExportUp20Regular className={styles.iconArrowExportUp} />}
                                                                                iconPosition="after"
                                                                                size="medium"
                                                                                onClick={() => {
                                                                                    onClickUseThisPrompt(prompt.prompt);
                                                                                }}
                                                                            >
                                                                                {CONST.USE_THIS_PROMPT}
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </AccordionPanel>
                                                    </AccordionItem>
                                                ))}
                                            </Accordion>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

import { useMemo } from "react";
import { Stack, IconButton } from "@fluentui/react";
import DOMPurify from "dompurify";

import { parseAnswerToHtml } from "./AnswerParser";
import { AnswerIcon } from "./AnswerIcon";

import { Marked } from "marked";
import { markedHighlight } from "marked-highlight";
import hljs from "highlight.js";
import { AnswerButton } from "./answer-buttons/AnswerButton";
import { GptVersions } from "../../utils/GptVersions";

import styles from "./Answer.module.css";

interface Props {
    threadId: string;
    index: number;
    answer: string;
    isSelected?: boolean;
    isLoading?: boolean;
    inProgress?: boolean;
    environment: any;
    modelName: string;
    evaluate: number;
}

export const Answer = ({ threadId, index, answer, isSelected, isLoading, inProgress, environment, modelName, evaluate }: Props) => {
    const parsedAnswer = useMemo(() => parseAnswerToHtml(answer), [answer]);
    const marked = new Marked(
        markedHighlight({
            langPrefix: "hljs language-",
            highlight(code, lang) {
                const language = hljs.getLanguage(lang) ? lang : "plaintext";
                return hljs.highlight(code, { language }).value;
            }
        })
    );
    const markedAnswer = marked.parse(parsedAnswer.answerHtml);
    const checkedAnswer = typeof markedAnswer === "string" ? markedAnswer : "";
    const sanitizedAnswer = DOMPurify.sanitize(checkedAnswer);
    const displayModelName = GptVersions.convertToDisplayString(modelName);

    return (
        <div className={styles.answer}>
            <section className={`markdown-body ${styles.markDownWrapper}`}>
                <Stack className={styles.answerContainer} verticalAlign="space-between">
                    <Stack.Item>
                        <Stack horizontal horizontalAlign="space-between">
                            <AnswerIcon />
                            <div
                                data-index={index}
                                className={`${styles.answerText} ${inProgress && styles.inProgress}`}
                                dangerouslySetInnerHTML={{ __html: sanitizedAnswer }}
                            ></div>
                        </Stack>
                    </Stack.Item>
                    {!isLoading && !inProgress && (
                        <Stack.Item>
                            <Stack horizontal horizontalAlign="end">
                                <AnswerButton
                                    threadId={threadId}
                                    index={index}
                                    answer={answer}
                                    environment={environment}
                                    evaluate={evaluate}
                                    modelName={displayModelName}
                                />
                            </Stack>
                        </Stack.Item>
                    )}
                </Stack>
            </section>
        </div>
    );
};
